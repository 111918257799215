import React, { useState } from 'react'
import emailjs from 'emailjs-com';

const SERVICE_ID = "service_jkx46x9";
const TEMPLATE_ID = "template_zeq4snm";
const PUBLIC_KEY = "m3QZMPBAadA9E9m0B";

const ContactForm = () => {

 const [submitted, setSubmitted] = useState(false);


    const handleOnSubmit = (e) => {
        e.preventDefault();
        emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, e.target, PUBLIC_KEY)
          .then((result) => {
            setSubmitted(true);
          }, (error) => {
          	alert('Error! Please try again.')
          });
        e.target.reset()
      };

  if (submitted) {
    return (
    	<>
    	<form onSubmit={handleOnSubmit}>
	       <div>
    		<span className="formSuccessMessage">
    		Message sent. We'll aim to respond within 3 working days.
    		<br />
    		<br /> 
    		</span>
    		</div>
	    <div className="form-group">
	        <div className="formElement">
	            <label for="from_name">Name</label>
	            <input type="text" className="form-control" id="from_name" name="name" required />
	        </div>
	    </div>
	    <div className="form-group">
	        <div className="formElement">
	            <label>Email</label>
	            <input type="email" className="form-control" id="from_email" name="email" required />
	        </div>
	    </div>
	    <div className="form-group">
	        <div className="formElement">
	            <label for="message">Message</label>
	            <textarea name="message" class="form-control" rows="8" cols="30" required />
	        </div>
	    </div>
	    <button type="submit" className="btn btn-primary btn-dark">Send</button>
	    </form>
	    </>
    );
  }

    return (
	    <form onSubmit={handleOnSubmit}>
	    <div className="form-group">
	        <div className="formElement">
	            <label for="from_name">Name</label>
	            <input type="text" className="form-control" id="from_name" name="name" required />
	        </div>
	    </div>
	    <div className="form-group">
	        <div className="formElement">
	            <label>Email</label>
	            <input type="email" className="form-control" id="from_email" name="email" required />
	        </div>
	    </div>
	    <div className="form-group">
	        <div className="formElement">
	            <label for="message">Message</label>
	            <textarea name="message" class="form-control" rows="8" cols="30" required />
	        </div>
	    </div>
	    <button type="submit" className="btn btn-primary btn-dark">Send</button>
	    </form>
    )
}

export default ContactForm;

import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import "./App.css";

/*Pages*/
import Home from "./pages/home";
import Info from "./pages/info";
import Contact from "./pages/contact";
import Agreement from "./pages/agreement";

/*Components*/
import LeftBorder from "./components/leftborder";
import RightBorder from "./components/rightborder";

function App() {
  function removeNav() {
    document.getElementById("menu-checkbox").checked = false;
  }

  return (
    <div>
      <Router>
        <div id="menu-toggle">
          <input type="checkbox" id="menu-checkbox" />
          <span></span>
          <span></span>
          <span></span>
          <ul id="menu">
            <Link to="/info">
              <li onClick={removeNav}>Info</li>
            </Link>
            <Link to="/contact">
              <li onClick={removeNav}>Contact</li>
            </Link>
          </ul>
        </div>
        <header>
          <a href="/" className="d-flex align-items-center">
            <strong className="logo">
              <Link to="/" onClick={removeNav}>
                KARANA
              </Link>
            </strong>
          </a>
        </header>
        <div>
          <div className="container">
            <LeftBorder />
            <div className="d-flex justify-content-center">
              <Switch>
                <Route exact path="/" component={Home} />
                <Route path="/info" component={Info} />
                <Route path="/contact" component={Contact} />
                <Route path="/agreement" component={Agreement} />
              </Switch>
            </div>
            <RightBorder />
          </div>
        </div>
      </Router>
      <footer>KARANA 2024 © | Company Reg Number 09407417</footer>
    </div>
  );
}

export default App;

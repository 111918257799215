import React, { Component } from 'react'
import { Link } from "react-router-dom"

class LeftBorder extends Component {
    render() {
        return (
            <div className="border-left">
                <Link to="/info"><p className="border-text">INFO</p></Link>
            </div>
        )
    }
}

export default LeftBorder


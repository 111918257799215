import React, { Component } from 'react'
import { Link } from "react-router-dom"

class RightBorder extends Component {
    render() {
        return (
            <div className="border-right">
                <Link to="/contact"><p className="border-text">CONTACT</p></Link>
            </div>
        )
    }
}

export default RightBorder


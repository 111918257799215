import React from 'react'
import "../App.css";
import { Link } from "react-router-dom"

export default function Info() {

    return (
        <div className="row info">
            <div className="col-md-12">
                <h1><strong>About</strong></h1>
                <p>With over a decade of experience, we’re passionate about empowering new business owners, charities and nonprofits with the tools they need to boost visibility, engagement and impact. </p>
<p>Our Comprehensive Website Package is designed to provide a hassle-free, end-to-end solution for establishing a robust online presence. We take care of everything from the initial setup to ongoing support, ensuring your website is professional, functional, and easy to manage.</p>

<p><strong>What’s Included</strong></p>
<ul>
<li>Consultation and Planning
<ul>
<li>Initial consultation to understand your business needs, goals, and target audience.</li>

<li>Customised website planning to outline the site structure, design preferences, and essential features.</li>
</ul>
</li>
</ul>


<ul>
<li>Domain and Hosting Setup
<ul>
<li>Assistance with selecting and registering a domain name. This is the text a user types into a browser window to reach your website. </li>



<li>Assistance with setting up reliable and secure hosting. Think of the website as the house and the hosting as the land it's built on.</li>



<li>Configuration of email accounts associated with your domain.</li>

</ul>
</li>
</ul>


<ul>
<li>Custom Website Design and Development
<ul>
<li>Creation of a unique, responsive website design that aligns with your brand identity. If you’re yet to establish a brand identity don’t worry, we’ll figure this out together.</li>



<li>Development of a fully functional website with essential pages (Home, About, Services, Contact, etc.).</li>



<li>Integration of features such as contact forms and social media links.</li>

</ul>
</li>
</ul>


<ul>
<li>Content Management System (CMS)
<ul>
<li>Implementation of a powerful, user-friendly CMS setup on WordPress that allows you to easily update your website content from an interface.</li>



<li>A guide on how to use the CMS effectively, including adding new pages, editing text, and uploading images.</li>



<li>Customisable templates and modules to expand and modify your website as your organisation grows.</li>

</ul>
</li>
</ul>


<ul>
<li>SEO Optimisation
<ul>
<li>Basic on-page SEO setup to improve your website’s visibility in search engine results.</li>

</ul>
</li>
</ul>


<ul>
<li>Launch and Post-Launch Support
<ul>
<li>Thorough testing of the website on multiple devices and browsers to ensure a smooth user experience.</li>
<li>Assistance with launching your website to the public.</li>



<li>One month of free technical support to address any issues or questions after the website goes live.</li>

</ul>
</li>
</ul>
                <p>Interested? The best way to contact us is through our <Link to="/contact"><u>contact form</u></Link> or by <a href="mailto:hello@karana.agency"><u>email</u></a>.</p>
          
</div>
</div>

    )
}
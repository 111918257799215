import React from 'react'

export default function Agreement() {
    return (
        <div>
            <h1>Agreement</h1>
            <p><b>If we work together, we'll ask you to sign this contract. It talks a little about our process and how we work - we've made it as easy to understand as possible.</b></p>
            <p>Between KARANA STUDIO LIMITED (trading as Karana) (Company No. 15716882)</p>
            <p>And [client name],</p>
            <p><b>Summary</b></p>
            <p>We’ll always do our best to fulfil your needs and meet your expectations, but it’s important to have things written down so that we both know what’s what, who should do what and when, and what will happen if something goes wrong. In this contract you won’t find any complicated legal terms or long passages of unreadable text. We have no desire to trick you into signing something that you might later regret. What we do want is what’s best for both parties, now and in the future.</p>
            <p>So in short;</p>
            <p>You ([customer name]), located at [customer address] (“You”) are hiring us (Karana Studio Limited) (“Us or We”) to:</p>
            <p> Redesign and develop the [customer name] website: </p>

            <ul>
                <li>Stage 1: Discovery, Design and UX</li>
                <li>Stage 2: Development </li>
                <li>Stage 3: Testing and Launch </li>
            </ul>
            <p>Starting on [kickoff date]</p>
            <p>For an estimated [x] days at a rate of [day rate] per day as outlined in our previous correspondence. Our working day is five hours long.</p>
            <p>In our discussions, we discussed adding the following phases to the project, but they are not included in this scope of work:</p>
            <ul>
                <li>[add items]</li>
            </ul>
            <p><b>What do both parties agree to?</b></p>
            <p><b>You</b></p> 
            <p>You have the authority to enter into this contract on behalf of yourself, your company or your organisation. You’ll give us the assets and information we need to complete the project. You’ll do this when we ask and provide it in the formats we request. You’ll review our work, provide feedback and approval in a timely manner, too. Deadlines work two ways, so you’ll also be bound by dates we set together. You also agree to stick to the payment schedule set out at the end of this contract.</p>
            <p><b>We</b></p>
            <p>We have the experience and ability to do everything we’ve agreed with you and we’ll do it all in a professional and timely manner. We’ll endeavour to meet every deadline that’s set and on top of that we’ll maintain the confidentiality of everything you give us.</p>
            <p><b>Phases</b></p>
            <p>Below is a breakdown of each project phase, as well as an estimated date for completion of each phase:</p>
            <table class="wp-table">
                <tr>
                  <th>PROJECT PHASE</th>
                  <th>ESTIMATED DATE OF COMPLETION</th>
                  <th>ESTIMATED NUMBER OF DAYS</th>
                </tr>
                <tr>
                  <td>Discovery, UX and Design</td>
                  <td>January 1st, 2024</td>
                  <td>XX</td>
                </tr>
                <tr>
                  <td>Development</td>
                  <td>January 1st, 2024</td>
                  <td>XX</td>
                </tr>
                <tr>
                  <td>Testing/Launch</td>
                  <td>January 1st, 2024</td>
                  <td>XX</td>
                </tr>
              </table>
              <br />
            <p>Each phase is assigned a range of days based on how long we expect that phase to take. Once we submit everything that is required of us for a particular phase, that phase is considered complete. In the event the project does not go to completion, you agree to pay for unbilled days that we have worked in the current phase.</p>
            <p>Throughout each project phase, we’ll be seeking your feedback. You agree to make yourself available to provide feedback. Although we’re going to work very hard to make sure you are completely satisfied, you agree that your approval is not needed for the purposes of considering a phase completed.</p>
            <p>This project will be considered completed upon the completion of all phases listed above and delivery of the deliverables or ends on [project completion date], whichever occurs first. Anything outside these deliverables and phases list or after [project completion date] will be considered out of scope.</p>
            <p><b>Design</b></p>
            <p>We create look-and-feel designs, and flexible layouts that adapt to the capabilities of many devices and screen sizes. We create designs iteratively and use predominantly HTML and CSS so we won’t waste time mocking up every template as a static visual. We may use visuals to indicate a creative direction (colour, texture and typography). </p>
            <p>You’ll have plenty of opportunities to review our work and provide feedback. We’ll provide video walkthroughs of designs, share links to an InVision project where you can drop comments directly onto the design, and later we’ll move to a development site. </p>
            <p><b>Text Content</b></p>
            <p>Unless agreed separately, we’re not responsible for uploading text or images to your website or creating every page. We will typically enter content for at least one page for each template type. We provide professional copywriting and editing services, so if you’d like us to create, edit, or input content for you, we’ll provide a separate estimate.</p>
            <p><b>Images</b></p>
            <p>You will supply graphic files in an editable, vector digital format including SVG, Adobe Illustrator (Ai) or EPS. You will provide high resolution digital photographs. If your design requires stock images, these assets—and the time we spend selecting them—aren’t included in our price. We can provide a separate estimate for stock images and research.</p>
            <p><b>Browser Testing</b></p>
            <p>You understand that browser testing no longer means attempting to make a website look the same in browsers of different capabilities or on devices with different size screens. You agree it does mean ensuring someone’s experience of a design should be appropriate to the capabilities of their browser or device. We will test our work only in current versions of major desktop browsers including those made by Apple (Safari), Google (Chrome), Microsoft (Edge), and Mozilla (Firefox). We also test on the latest browser versions on iPhone, iPad and simulated testing on an Android mobile and an Android tablet device. We won’t test in older versions unless agreed separately. If you need an enhanced design for an older browser or specific device, we will provide a separate estimate for that.</p>
            <p><b>Delivery and Backup</b></p>
            <p>At the start of the project, you’ll set up a free GitHub account. We’ll use this account to deliver HTML, CSS, and JavaScript files, as well as production and optimised images. You will retain these files as we’re not required to keep copies.</p>
            <p><b>Hosting</b></p>
            <p>We don’t offer support for website hosting or email. You may already have professional hosting and you might even manage that hosting in-house; if you do, great. If you don’t already have hosting, we’ll recommend a provider and ask you to set up an account. Setting up your website on a server is included in our price. After that, updates to your website and management of your hosting will be up to you unless a maintenance contract has been agreed. </p>
            <p><b>Technical Support & Maintenance</b></p>
            <p>Once your site is launched, the updates, management and security of the site is up to you, however we also offer maintenance contracts and can provide a separate quote for that.</p>
            <p><b>Search Engine Optimisation (SEO)</b></p>
            <p>You understand we don’t guarantee improvements to your search engine ranking, but that the websites we develop are accessible to search engines.</p>
            <p><b>Licenses</b></p>
            <p>The development of the site may require the use of software (such as the content management system, add-ons, plugins, fonts) that require licenses. You understand the cost of these is not included in the quote, but we’ll advise about these as soon as we can.</p>
            <p><b>Changes and Revisions</b></p>
            <p>We won’t limit your ability to change your mind. Our estimate is based on the work and time we think is needed to deliver everything we’ve agreed to deliver. If you change your mind, adding extra work won’t be a problem and we’re happy to be flexible. We’ll provide a separate estimate for any additional work.</p>
            <p><b>Legal Stuff</b></p>
            <p>We carry out work in accordance with good industry practice and at the standard expected from qualified people with relevant experience. That said, we can’t guarantee our work will be free from errors. We won’t be liable to you or any third-party for damages, including lost profits, lost savings or other incidental, consequential or special damages, even if you’ve advised us of them.</p>
            <p>Your liability to us will be limited only to the price stated in this contract. You won’t be liable to us or any third-party for damages, including lost profits, lost savings or other incidental, consequential or special damages, even if we’ve advised you of them. </p>

            <p>Finally, if any provision of this contract shall be unlawful, void, or for any reason unenforceable, then that provision shall be deemed severable from this contract and shall not affect the validity and enforceability of any remaining provisions.</p>
            <p>Phew.</p>
            <p><b>Intellectual Property Rights</b></p>
            <p>You guarantee the written content you provide is original, or that you have the rights to use it. You also guarantee you have licences to use images which are owned by photographers or have been purchased from stock libraries. You agree to protect us from any claim by owners of copyrighted material. When our work requires licensed fonts or images from stock libraries, we’ll ask you to purchase them so you’ll be licensed to use them. We guarantee our work is original. When you’ve paid us in full—and if this contract hasn’t been terminated—we’ll assign intellectual property rights to you as follows:</p>
            <ul>
            <li>You’ll own the graphic files we produce during your project. We’ll give you permission to use these files for any purpose.</li>
            <li>We’ll own the unique combination of elements which constitute the complete design. We’ll license it to you, exclusively and in perpetuity, for this project only.</li>
            </ul>
            <p><b>Displaying Our Work</b></p>
            <p>We love to show off our work, so we reserve the right to display any aspect of our work as long as doing so doesn’t breach any confidentiality agreement. This may include sketches, work-in-progress designs and the finished project on our website, in magazine articles, or in books.</p>
            <p>Our preference is always to show our work and involvement in projects after they’re live, especially where a project aligns closely with our core values. In cases where we can’t publicly discuss our work, we’ll sign an NDA to clarify everyone’s expectations. This increases the total project fee by 30% as we’re changing the project’s visibility and the promotional value is significantly reduced.</p>
            <p>We’ll also include a subtle credit link to our website in the footer of your site. We’re happy to remove this if you prefer, just let us know.</p>
            <p><b>Payment Schedule</b></p>
            <p>You understand how important it is for a small business that you pay your bills promptly.</p>
            <p>Because we have reserved a certain block of time to complete the project for you, we take a booking deposit equivalent to [booking deposit duration]. Once we have used this time, subsequent days are billed weekly.</p>
            <p>You agree to stick tightly to the following payment schedule.</p>
            <table class="wp-table">
                <tr>
                  <th>PAYMENT</th>
                  <th>AMOUNT</th>
                  <th>DUE DATE + TERMS</th>
                </tr>
                <tr>
                  <td>Booking deposit</td>
                  <td>£XXXX.XX</td>
                  <td>[booking deposit duration] non-returnable commencement deposit</td>
                </tr>
                <tr>
                  <td>Subsequent days/phases</td>
                  <td>£XXXX.XX</td>
                  <td>Subsequent days billed weekly and due within 7 days of receipt</td>
                </tr>
            </table>
            <br />
            <p>Note: The project and corresponding schedules are only confirmed/reserved once both the contract has been signed and the booking deposit has been paid.</p>
            <p>Please send clearly referenced payments to:</p>
             <table class="wp-table">
                <tr>
                  <th>ACCOUNT DETAILS</th>
                </tr>
                <tr>
                  <td>Account Name</td>
                  <td>XXXXXXXX</td>
                </tr>
                <tr>
                  <td>Account Number</td>
                  <td>XXXXXXXX</td>
                </tr>
                <tr>
                  <td>Sort Code</td>
                  <td>XX-XX-XX</td>
                </tr>
                <tr>
                  <td>Bank Name</td>
                  <td>XXXXXXXX</td>
                </tr>
            </table>
            <br />
            <p>Our payment terms are seven days from the date of invoice. We issue invoices electronically and our bank account details will be included. Our invoices will be in GBP. If your currency is different, you agree your payments will be the same value.</p>
            <p>You agree to pay all charges associated with international transfers of funds. We reserve the right to charge interest on all overdue debts at the rate of 5% per month or part of a month.</p>
            <p><b>Project Pauses</b></p>
            <p>If a client deliverable — such as input, approvals, or payment — is late more than 10 business days the project will be considered “on hold.” Once the deliverable is received and the project is re-activated it will be rescheduled based on our current workload and availability. Just to say it loud and clear, it could be weeks to get you back in the system if the project is put on hold.</p>
            <p>You may voluntarily choose to pause the project. To do this, let us know in writing that you want to pause and the duration of the pause. The project will then be rescheduled based on our mutual availability, which may not be immediately after the pause.</p>
            <p>Both parties agree that if a pause lasts longer than two weeks, we may need to rescope the project, including changing the completion date for the project and dates phases are due.</p>
            <p>When we have fixed deadlines, we arrange our schedule to devote specific time to your project. If you request project pauses that total more than 30 days over the project’s duration, you agree to pay an additional fee worth 5% of the total project fee.</p>
            <p>If the requested pause lasts more than 60 days, this will be considered to be a project cancellation (see the “Cancellations” clause below).</p>
            <p><b>Cancellations</b></p>
            <p>Either party can terminate this agreement by giving the other party 10 days notice. The project may also be cancelled by us if, after project commencement, you stop communicating with us for a period of 30 days. If the project is terminated, any completed files and deliverables for project phases already paid for will be transferred to you under the terms of the “Intellectual Property rights” clause above. Because we have reserved time for the project, you may be charged an additional sum equal to 10% of the original estimate.</p>
            <p><b>But where's all the horrible small print?</b></p>
            <p>Just like a parking ticket, neither of us can transfer this contract to anyone else without the other’s permission.</p>
            <p>We both agree to adhere to laws and regulations in relation to our activities under this contract and not to cause the other to breach any relevant laws or regulations.</p>
            <p>This contract stays in place and need not be renewed. Although its language is simple, the intentions are serious and this contract is a legal document under exclusive jurisdiction of English and Welsh courts.</p>
            <p><b>The Dotted Line</b></p>
            <p>Signed by SGP on behalf of Karana Studio Limited (trading as Karana)</p>
            <p>…………………………</p>
            <p>Signed by [name] on behalf of [client name]</p>
            <p>…………………………</p>
            <p>Date: [date]</p>
            <p>Everyone should sign above and keep a copy for their records.</p>
        </div>
    )
}  
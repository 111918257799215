import React from 'react'
import { Link } from "react-router-dom";

export default function Home() {
    return (
        <div>
            <div className="row-center">
                <Link to="/info">
                    <h1 className="hero">A web design & development agency helping small businesses, charities and nonprofits get online.</h1>
                </Link>
            </div>
        </div>
    )
}
import React from 'react'
import ContactForm from "../components/contactform";

export default function Contact() {
    return (
        <div class="responsive-two-column-grid">
            <div>
                <h1><strong>Contact us</strong></h1>
                <p>hello@karana.agency</p> 
            </div>
            <div>
                <ContactForm />
            </div>
        </div>
    )
}  